
.Breakfast-section{
background-color: red;
  font-family: "Share Tech Mono", monospace;
  font-weight: 500;
  font-style: normal;
box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Adds a subtle shadow for a 3D effect */
padding: 20px; /* Adds space inside the card around the content */
margin: 0; /* Adds space around the card */
transition: all 0.3s ease; /* Smooth transitions for hover effects */
  &:hover {
    box-shadow: 0 0 1px rgba(39, 44, 49, 0.1),
      0 3px 16px rgba(39, 44, 49, 0.07);
    transition: all 0.3s ease;
    transform: translate3D(0, -1px, 0);
  }

}
.Breakfast-Header {
  display: grid;
  grid-template-columns: 50% 50%; /* Header text takes 75%, carousel takes 25% */
  align-items: center;
  padding-top: 3.5rem;
  margin-right: 2rem;
  z-index: 1;
}

.bec{
  border-radius: 25px;
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-left: -0px;
  margin-top: 55px;
}


.specials{
  font-family: "KaushanScript-Regular";
  padding-left: 2%;
  font-weight: 900;  
  font-size: 2rem;
}
  
.specialslist {
  padding-left: 2%;
  font-weight: 900;
  font-size: 1.5rem;

}
.breakfast-list {
  padding-left: 2%;
  font-weight: 700 ;
  font-size: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.list-column {
  flex: 1 0 calc(15% - 10px); /* Adjust the width as needed */
  margin-right: 10px;
}



.extras {
  padding-left: 2%;
  font-weight: 900;  
  font-size: 1.5rem;
  padding: 2%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.card {
  flex: 1 0 calc(48% - 10px); /* Adjust the width as needed */
  margin-right: 10px;
}
li {list-style-type: none;}

ul {
  list-style-type: none; /* This removes the bullet points */
  /* This removes the default padding on the left, often useful for aligning the list items to the left */
}
.breakfast-card{
  background-color: green;
  border-radius: 25px;
  color: rgb(0, 0, 0);
 
}


