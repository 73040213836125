.Best{
  font-family: 'KaushanScript-Regular';
  color: yellow;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11rem;
  padding: 1rem;
  flex: 1;
  z-index: 1;
  
  

}

.Subs {
  font-family: 'Phosphate';
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11rem;
  padding-bottom: 0;
  flex: 3;
  
  position: absolute;
}


.Logo {
    
    margin-top: 0rem;
    position:static;
    width: 100%;
  
    background-color: green;
    background-size:cover;
    background-position: center ;
    display: flex;
    align-items: center;
    justify-content:space-between;
    overflow: hidden;
  }
  
  .carousel-container {
    flex: 2;
    width: 100%; 
    margin: 0 auto;
   

  }
  
  .carousel-image {
    width: 24rem;
    height: auto;
    object-fit: cover;
    
  }

  