.Footer-container {
  font-family:Georgia, 'Times New Roman', Times, serif ;
     margin-top: -4rem;
   font-weight: bolder;
     display: grid;
   place-items: center;
   color: Black;
   text-decoration: none; 
   position: relative;
   z-index: 1;
 padding-bottom: 4rem;
 
 }
 
 a {
     color: inherit; /* This makes the link color the same as the parent element */
     text-decoration: none; /* This removes the underline from links */
   }
   
   a:hover {
     text-decoration: underline; /* Optional: adds underline on hover for visual feedback */
   }
   