.HotSubsWraps-Header {
  display: grid;
  grid-template-columns: 50% 50%; /* Header text takes 50%, carousel takes 50% */
  align-items: center;
  padding-top: 3.5rem;
  margin-right: 2rem;
  color: red;
  z-index: 1;
  line-height: 1.2; 
}
.carousel-image-hs{
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-left: -0px;
  margin-top: 55px;
}



ul {
  list-style-type: none; /* This removes the bullet points */
  padding-left: 0; /* This removes the default padding on the left */
}

.HotSubsWraps-section {
  background-color: goldenrod;
  padding: 20px; /* Adds space inside the card around the content */
  margin: 0px; /* Adds space around the card */
  transition: all 0.3s ease; /* Smooth transitions for hover effects */
}

.HotSubsWraps-section:hover {
  box-shadow: 0 0 1px rgba(39, 44, 49, 0.1),
    0 3px 16px rgba(39, 44, 49, 0.07);
  transition: all 0.3s ease;
  transform: translate3D(0, -1px, 0);
}

.HotSubsWraps-list {
  padding-left: 2%;
  font-weight: 700;
  font-size: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.HotSubsWraps-card {
  background-color: white;
  border-radius: 25px;
}
