.Drinks-Header{
  display: grid;
  grid-template-columns: 60% 40%; /* Header text takes 50%, carousel takes 50% */
  align-items: center;
  padding-top: 3.5rem;
  margin-right: 2rem;
  color: white;
  z-index: 1;
 }
 
 .drinkMenuImages{
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-left: -0px;
  margin-top: 5px;
}
 ul {
  list-style-type: none; 
  padding-left: 0;  
  
}

.DrinksCombos-list{
  padding-left: 2%;
  padding-right: 2%;
  font-weight: 700 ;
  font-size: 1.5rem;
  display: flex;
  
  justify-content: space-between;
  padding-top: 0rem;
    color:black;
    position: relative;
    z-index: 1;  
    font-family: "Share Tech Mono", monospace;
  
}
.DrinksCombos-Card {
 
    background-color: green;
    border-radius: 25px;
    font-family: 'Share Tech Mono';
  }
.DrinksCombos-section {
  background-color: red;
    padding: 20px;
    padding-bottom: 25px; /* Adds space inside the card around the content */
  margin: 0px; /* Adds space around the card */
  transition: all 0.3s ease; /* Smooth transitions for hover effects */
  
 }

.DrinkCombo{border-radius: 25px;
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-left: -0px;
  margin-top: 55px;}
.Drinks-column {
  margin-top: 0; 
  padding-left: 2%;
  font-weight: 700 ;
  font-size: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

}
.drinkMenuImages {
  width: 400px;
  padding-right: 8rem;
}