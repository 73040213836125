.FooterHeader {
    background: black;
    border: none;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    font-size: 2.25rem;
    color: black;
    cursor: pointer;
    position: fixed;
    bottom: 0; /* Anchors to the bottom of the page */
    left: 0;
    width: 100%; /* Takes full width */
    z-index: 10000;
    display: flex;
    justify-content: center; /* Centers the content */
    align-items: center;
}

