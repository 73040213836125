
.KidsSubs-Header{
   
  display: grid;
  grid-template-columns: 50% 50%; /* Header text takes 75%, carousel takes 25% */
  align-items: center;
  padding-top: 3.5rem;
  margin-right: 2rem;
  z-index: 1;
  line-height: 1.2; 
 }
 ul {
  list-style-type: none; /* This removes the bullet points */
  /* This removes the default padding on the left, often useful for aligning the list items to the left */
}
.KidSub {
  border-radius: 25px;
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-left: -0px;
  margin-top: 55px;

}
.Kidsmeal {  border-radius: 25px;
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-left: -0px;
  margin-top: 55px;
}
.salads-Header {
  display: grid;
  grid-template-columns: 60% 40%; /* Header text takes 75%, carousel takes 25% */
  align-items: center;
  padding-top: 3.5rem;
  margin-right: 2rem;
  z-index: 1;
}

.Salad{
  border-radius: 25px;
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-left: -0px;
  margin-top: 0;
  margin-bottom:0;

}

.salad-Card{
  background-color: white;
  border-radius: 25px;
 
}

.salads-section {
 
    padding-top: 2.5rem;
    color:black;
    position: relative;
    z-index: 1;  
    font-family: "Share Tech Mono", monospace;
  }
  
 
   ul {
    list-style-type: none; /* This removes the bullet points */
    /* This removes the default padding on the left, often useful for aligning the list items to the left */
  
  }
  .salads-section{
    
    background-color: goldenrod;
    padding: 20px; /* Adds space inside the card around the content */
    margin: 0px; /* Adds space around the card */
    transition: all 0.3s ease; /* Smooth transitions for hover effects */
  
    
      &:hover {
        box-shadow: 0 0 1px rgba(39, 44, 49, 0.1),
          0 3px 16px rgba(39, 44, 49, 0.07);
        transition: all 0.3s ease;
        transform: translate3D(0, -1px, 0);
      }
    
    }
    .salads-list{ 
      margin-top: 0; 
      padding-left: 2%;
      font-weight: 700 ;
      font-size: 1.5rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    
  
    }
    
 .KidsSubs-list {
  font-size: 1.5rem;
  padding-left: 2%;
  font-weight: 700 ;
  font-size: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

 } 
 .Kids-Combos-list {
  font-size: 1.5rem;
  padding-left: 2%;
  font-weight: 700 ;
  font-size: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
 }