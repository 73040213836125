@font-face {
  font-family: 'Phosphate';
  src: url('./components/Fonts/PhosphateRR Solid.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'KaushanScript-Regular';
  src: url('./components/Fonts/KaushanScript-Regular.ttf') format('truetype');
  font-style:normal;
}
.App {
  width: 100%;
  margin: 0 auto;
}
.list-column{
  font-family: "Share Tech Mono", monospace;
}
.Banner {
  position: relative; /* Ensure the Banner is positioned relative to its normal flow */
  z-index: 1000; /* High z-index value to make sure it stays above other elements */
}

@media (min-width: 375px) {
  .Logo {
    height: 13rem;
    width: 100%;
  }


  .Caterings-Header{
    font-size: 2.75rem;
    font-family: "KaushanScript-Regular";
    font-weight: 900;
    font-style: bold;
  }
  .DrinkCombo {
  width: 8rem;
}

  .hero {
    width: 100%;
    height: 15rem;
  }
  .phoneNumber {
    font-size: 3rem;
  }
  .Beers {
    display: none;
    z-index: 1;
  }
  .Best, .Subs {
    font-size: 4rem; /* Further reduce font size for the smallest screens */
    padding: 0;
  }
  
  .Subs {
    padding-left: 62.5%;
  }
  .carousel-image{
 
    height: auto;
    object-fit: cover;
    width: 40%;
    margin: 0;
 

  }
 
    .location-info {
  
      display: flex;
      flex-direction: column; /* Stack address and hours vertically */
   
      width: 100%;
      margin: 0 auto;
    }
  
    .address {
      text-align: center;
      font-size: 1.5rem;
      padding-right: 0;
      font-family: 'impact';
      margin-bottom: 0;
    }
    .hours{
      text-align: center;
      font-size: 1rem;
      padding-top: 0;
      padding-left:0rem;
      margin-top: 0;
      padding-bottom: 3rem;
    }
  

    
  
  
  .drinkMenuImages {
    width: 7rem; 
  }
  .drinkMenuImages1{
    width: 7rem;
  }
  .Breakfast-Header {
    font-size: 2rem;  
    font-family: "KaushanScript-Regular";
    font-weight: 900;
    font-style: bold;
      padding-left: 1.75rem;
      padding-top: 2.5rem;
      color:yellow;
      position: relative;
      z-index: 1;  
  }  
  .ColdSubsWraps-Header,
  .HotSubsWraps-Header,
  .salads-Header,
  .KidsSubs-Header,
 
  .Drinks-Header {
    font-size: 2rem;  
    font-family: "KaushanScript-Regular";
    font-weight: 900;
    font-style: bold;
      padding-left: 1rem;
      padding-top: 2.5rem;
      color:red;
      position: relative;
      z-index: 1;   /* Adjust the font size for slightly larger screens */ 
    }
    .Drinks-column{
      padding-left: 2%;
      padding-right: 2%;
      font-weight: 1000 ;
      font-size: .75rem;
      display: flex;
     justify-items: auto;
      padding-top: 0rem;
        color:black;
        position: relative;
        z-index: 1;  
        font-family: "Share Tech Mono", monospace;
    }
    .FooterHeader
    {display: contents;
      background: white;
    height:40px;
      border: none;
      margin-bottom: 0;
      margin-left: 0;
      font-size: 1rem;
      color: black;
      font-family: "Share Tech Mono", monospace;
      cursor: pointer;
      position: fixed;
      bottom: 0; /* Anchors to the bottom of the page */
      left: 0;
      width: 100%; /* Takes full width */
      z-index: 10000;
      display: flex;
      justify-content: center; /* Centers the content */
      align-items: center;}
}

@media (min-width: 425px) {
  .navitem {
    padding-right: 2rem;
    position: cover;
    display: none;
    font-size: 1rem;
    justify-content:center;
    height: 1rem;
    background-color: rgba(255, 255, 255, 0.95);
    align-items: center;
    flex-direction: row;
}
  .Caterings-Header{
    font-size: 3rem;
    font-family: "KaushanScript-Regular";
    font-weight: 900;
    font-style: bold;
  }

  .DrinkCombo {
    width: 9rem;
  }
  .Beers{
    display: none;
  }

  .location-info {
    display: flex;
    flex-direction: column; /* Stack address and hours vertically */
    align-items: center;
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }
  .beers-container{
    height: 2rem;
  }
  .address {
    text-align: center;
    font-size: 1.5rem;
    padding-right: 0;
    font-family: 'impact';
    margin-bottom: 0;
  }
  .hours{
    text-align: center;
    font-size: 1rem;
    padding-top: 0;
    padding-right: 0;
    margin-top: 0;
    padding-bottom: 3rem;
  }

  .Logo {
    height: 13rem;
    width: 100%;
  }
  .Best  {
    font-size: 4.5rem; /* Further reduce font size for smaller screens */
    padding: 0.45rem;
  }
  .Subs {
    font-size: 4.5rem;
    padding-left: 60%;
  }
  .carousel-image {
    width: 11rem;
    height: auto;
    object-fit: cover;
    
  }
  .salads-Header  {
    font-size:4rem;
    color: green;
    font-weight: 900;
}

  .Breakfast-Header{
    font-size: 2.25rem;
    color: yellow;
  }
  .ColdSubsWraps-Header
  {
    font-size: 3rem;
    color: red;
    margin-top: 0;
  }
  .ColdCut{
    margin-bottom: 2rem;
    padding-bottom: 0;
  }
  .HotSubsWraps-Header{
    font-size: 3rem;
    color: red;
    margin-top: 0;
  }
  .drinkMenuImages1{
    width: 10rem;
  }
  
  .KidsSubs-Header{
  font-size:4rem;
  color: red;}
  
  .Drinks-Header {
    font-size:4rem;
    color: yellow;
    font-family: "KaushanScript-Regular";
      padding-left: 1rem;
      padding-top: 1rem;
      position: relative;
      z-index: 1;   /* Adjust the font size for larger screens */
  }
  .Drinks-column{
    padding-left: 2%;
    padding-right: 2%;
    font-weight: 700 ;
    font-size: 1rem;
    display: flex;
   justify-items: auto;
    padding-top: 0rem;
      color:black;
      position: relative;
      z-index: 1;  
      font-family: "Share Tech Mono", monospace;
  }
  .navitems.open {
    display: flex; /* Shown when the menu is open */
    width: 100%;}
}



@media (min-width: 768px) {
  .hero {
    width: 100%;
    height: 20rem;
  }

  .Beers {
    display: flex;
    width: 17rem; /* Adjust width as needed */
    height: auto;
    object-fit: contain;
    margin: 0;
    padding-top: 50rem;
    z-index: 1;
    
  }


  .beers-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0;
  }
  .location-info {
    display: flex;
    flex-direction: column; /* Stack address and hours vertically */
    align-items: center;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    padding-top: 0;
    padding-bottom: 4rem;
    padding-right: 0%;
    z-index: 2;
  }
  .address {
    text-align: center;
    font-size: 2rem;
    padding-right: 10%;
    font-family: 'impact';
    margin-bottom: 0;
    padding-top: 14rem;
  }
  .hours{
    text-align: center;
    font-size: 1.5rem;
    padding-top: 0;
    padding-right: 10%;
    margin-top: 0;
    padding-bottom: 12.5rem;
  }

  .Logo {
    height: 25rem;
    overflow-x: hidden;
  }
  .Best  {
    font-size: 8rem;
   padding-left: 2%;
  }
  .Subs {
    font-size: 8rem;
    padding-left: 62%;
  }
  .carousel-image {

width: 18rem;
padding-top: 1rem;
padding-left: 0;
  }

.drinkMenuImages1{
  width: 20rem;
}
.drinkMenuImages{
  width: 15rem;
}
  .salads-Header{
    font-size:9rem;
    color: green;
    font-weight: 900;
}
.KidsSubs-Header
  {
    font-size:10rem;
    color: red;}
    .Drinks-Header {
      font-size: 8rem;
      color: yellow;
    }
  .Breakfast-Header
  {
    font-size: 5rem;
  }
  .Caterings-Header{
    font-size: 6rem;
  }
  .ColdSubsWraps-Header{
    font-size: 6rem;
    color: red;
  }
 .DrinkCombo {
    width: 19rem;
  }
  .HotSubsWraps-Header

  {
    font-size: 6rem;
      font-family: "KaushanScript-Regular";
      font-weight: 900; 
      font-style: normal;
        padding-left: 1rem;
        padding-top: 2.5rem;
        color:red;
        position: relative;
        z-index: 1;  
     } 
     .Drinks-column
      {
       padding-left: 2%;
       padding-right: 2%;
       font-weight: 700 ;
       font-size: 1.5rem;
       display: flex;
      justify-items: auto;
       padding-top: 0rem;
         color:white;
         position: relative;
         z-index: 1;  
         font-family: "Share Tech Mono", monospace;
     }
     .list-column1{
      color: white;

     }
     
  }
  @media (min-width: 932px) {
    .Best  {
      font-size: 10rem;
     padding-left: 5%;
    }
    .Subs {
      font-size: 10rem;
      padding-left: 60%;
    }
    .navitem {
      position: fixed;
      display: flex;
      padding: 1rem;
      justify-content: space-between;
      height: auto;
      background-color: rgba(255, 255, 255, 0.95);
      align-items: center;
      flex-direction: row;
  }
      .Breakfast-Header{
        font-size: 6rem;
        color: yellow;
      }
      .carousel-image {
        width: 17rem;
        height: auto;
        object-fit: cover;
        padding-bottom: 0rem;
    }
    .hero {
      width: 100%;
      height: 20rem;
    }
  
    .Beers {
      display: flex;
      width: 20rem; /* Adjust width as needed */
      height: auto;
      padding-top: 58rem;
      object-fit: contain;
      margin: 0;
      z-index: 1;
    }
  
  
    .beers-container{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 2rem 0;
      margin: 0;
    }
    .location-info {
      display: flex;
      flex-direction: column; /* Stack address and hours vertically */
      align-items: center;
      text-align: center;
      width: 100%;
      margin: 0 auto;
      padding-top: 0;
      padding-bottom:0;
      padding-right: 0;
      z-index: 2;
    }
    .address {
      text-align: center;
      font-size: 2rem;
      padding-top: 0;
      padding-right: 0;
      margin-top: 0;
      font-family: 'impact';
      margin-bottom: 0;
      padding-top: 14rem;
    }
    .hours{
      text-align: center;
      font-size: 1.5rem;
      padding-top: 0;
      padding-right: 0;
      margin-top: 0;
      padding-bottom: 18rem;
    }
  }
  @media (min-width: 1024px)
  {
    .Best  {
      font-size: 10rem;
     padding-left: 5%;
    }
    .Subs {
      font-size: 10rem;
      padding-left: 60%;
    }
    .navitem {
      position: fixed;
      display: flex;
      padding: 1rem;
      justify-content: space-between;
      height: auto;
      background-color: rgba(255, 255, 255, 0.95);
      align-items: center;
      flex-direction: row;
  }
      .Breakfast-Header{
        font-size: 6rem;
        color: yellow;
      }
      .carousel-image {
        width: 17rem;
        height: auto;
        object-fit: cover;
        padding-bottom: 0rem;
    }
    .hero {
      width: 100%;
      height: 20rem;
    }
  
    .Beers {
      display: flex;
      width: 20rem; /* Adjust width as needed */
      height: auto;
      padding-top: 58rem;
      object-fit: contain;
      margin: 0;
      z-index: 1;
    }
  
  
    .beers-container{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 2rem 0;
      margin: 0;
    }
    .location-info {
      display: flex;
      flex-direction: column; /* Stack address and hours vertically */
      align-items: center;
      text-align: center;
      width: 100%;
      margin: 0 auto;
      padding-top: 0;
      padding-bottom:0;
      padding-right: 0;
      z-index: 2;
    }
    .address {
      text-align: center;
      font-size: 2rem;
      padding-top: 0;
      padding-right: 0;
      margin-top: 0;
      font-family: 'impact';
      margin-bottom: 0;
      padding-top: 14rem;
    }
    .hours{
      text-align: center;
      font-size: 1.5rem;
      padding-top: 0;
      padding-right: 0;
      margin-top: 0;
      padding-bottom: 18rem;
    }
  }