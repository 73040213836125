
.phoneNumber{
  background-size: cover;
  height: 5rem;
  background:black;
  color: white;
  display: grid;
  place-items: center;
  font-size: 4rem;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-sersif;
  border: 5px solid #ffffff;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding-bottom: 0;
}
.sticky-banner {
  position: fixed;
  top: 0px;
  width: 100%;
  left: 0;
  text-align: center;
  height: 4rem;
  background: black;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}
.hero {
  background-color: white;
  background-size: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40rem;
  margin: 0 auto;
  padding: 0;
}
.beers-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
   z-index: 1;
  margin-bottom: 2rem; /* Adjust this value as needed */

}

.address {
  text-align: center;
  font-size: 2rem;

}

.hours {
  font-size: 2rem;  
  font-family: "KaushanScript-Regular";
  font-weight: 900;
  font-style: bold;
    color:black;
    z-index: 1;  
}

.Beers {
  width: 8rem; /* Adjust the width as needed */
  height: auto;
  object-fit: contain;
  margin: 0;
  padding-top: 40rem;
  flex: 0 0 auto; /* Prevents the image from stretching or shrinking */
}

.location-info {
  display: flex;
  flex: 1; /* Allows the center content to share space equally */
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0;
  padding: 0 2rem;
  z-index: 2;
}

